/* eslint-disable no-param-reassign */
/* eslint-disable no-self-assign */
/* eslint-disable global-require */
import { Map, InfoWindow, Marker, GoogleApiWrapper , Polygon } from 'google-maps-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { Loading } from '@postidigital/posti-components';
import { Dialog } from 'primereact/dialog';
import DailyPulseFilter from './DailyPulseFilter.js';
import { translation } from '../../locales/translation.js';
import './DailyPulse.css';
import { MapRegions } from './MapRegions.js';
import { getJsonBody } from '../../utility/commonUtility.js';
import apiUrl from '../../urlConstants'
import { CommonService } from '../../service/CommonService.js';
import DailyPulseGraph  from "./DailyPulseGraph.js";
import Toastr from '../../shared/Toastr';
import mapStyles from './mapStyles'
import CustomApiErrorDialog from '../Configuration/CustomApiErrorDialog.js';

const translator = new LocalizedStrings(translation);

const weekGrid =[ '19.1%', '30.5%', '42%', '53.5%', '65%', '76.5%', '88%', '99.3%' ];
const monthGrid =[ '15%', '23%', '30.5%','37.5%','45.8%','53.5%','60.8%','68.5%','76.4%','83.8%','91.5%','99.4%' ];
const quaterGrid=[ '31%','54%','76.5%','99.4%' ];

// let a;
let colorArrayIndex = []
// let initialColor = [ '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5', '#D5D5D5' ]

export class DailyPulse extends Component {
  constructor(props){
    super(props)
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      counter: 0,
      counter1:0,
      regionMock: MapRegions,
      selectedInterval:'Weekly',
      filterByInterval: 'Weekly',
      dataGraph:[],
      // dataGraph1:[],
      intervalSelect: '',
      intervalSelectByTerminal: '',
      zoom: false,
      otherLocationLong: null,
      otherLocationLat: null,
      loading: null,
      filterCCId: null,
      filterCCName : null,
      fliteredBlock:[], 
      colorArray: [],
      MonthlyData:'',
      QuarterlyData:'',
      isDrag:false,
      zoomIndex:10,
      isApiError:false
      // test: true
      
    };
    this.commonService = new CommonService();
    // this.polygonRef = React.createRef()
    // this.polygonRef1 = React.createRef()
    // this.polygonRef2 = React.createRef()
    // this.polygonRef3 = React.createRef()
    // this.polygonRef4 = React.createRef()
    // this.polygonRef5 = React.createRef()
    // this.polygonRef6 = React.createRef()
    // this.polygonRef7 = React.createRef()
    // this.polygonRef8 = React.createRef()
    // this.polygonRef9 = React.createRef()
    // this.polygonRef10 = React.createRef()
    // this.polygonRef11 = React.createRef()
    // this.polygonRef12 = React.createRef()
    // this.polygonRef13 = React.createRef()
    // this.polygonRef14 = React.createRef()
    // this.polygonRef15 = React.createRef()
    // this.polygonRef16 = React.createRef()
    // this.polygonRef17 = React.createRef()
    // this.polygonRef18 = React.createRef()
    // this.polygonRef19 = React.createRef()
    // this.polygonRef20 = React.createRef()
    // this.polygonRef21 = React.createRef()
    // this.polygonRef22 = React.createRef()
    // this.polygonRef23 = React.createRef()
    // this.polygonRef24 = React.createRef()
    // this.polygonRef25 = React.createRef()
    // this.polygonRef26 = React.createRef()
    // this.polygonRef27 = React.createRef()
    // this.polygonRef28 = React.createRef()
    // this.polygonRef29 = React.createRef()
    // this.polygonRef30 = React.createRef()
    // this.polygonRef31 = React.createRef()
    // this.polygonRef32 = React.createRef()
    // this.polygonRef33 = React.createRef()
  }
 
  componentDidMount() {
    const { filterByInterval, intervalSelect } = this.state;
    
    setTimeout(() => {
      this.mapDataByArea(filterByInterval)
      this.dataLineGraph(filterByInterval)
      this.mapData(filterByInterval)
      // this.mapDataQuarterly('Quarterly')
      // this.mapDataMonthly('Monthly')
      const c = this.getColors(intervalSelect,-1,true,0)
      // const { colorArray }=this.state;
      // console.log(c, this.props.browserLanguage ,'cccccccccccccccccccccc')
      colorArrayIndex = c
      this.setState({
        colorArray: c,
      })
    }, 4000);
  }

  componentDidUpdate(prevProps) {
    const { selectedCostCenter } = this.props;
    const { filterByInterval } = this.state;
    if (
      prevProps.selectedCostCenter !== selectedCostCenter &&
      prevProps.selectedCostCenter.costCenterName !== ''
    ) {
      this.dataLineGraph(filterByInterval)
      // let c = this.getColors(intervalSelect,0,false)
      this.setState({
        otherLocationLat: null,
        otherLocationLong: null,
        selectedInterval : 'Weekly',
        filterCCId:null,
        filterCCName:null,
        colorArray:colorArrayIndex,
        zoom: false,
        counter : 0,
      },
      // ()=>{
      //   setTimeout(()=>{
      //     if(colorArrayIndex)
      //     {this.polygonRef.current.polygon.setOptions({ fillColor: colorArrayIndex[ 0 ] })
      //       this.polygonRef1.current.polygon.setOptions({ fillColor: colorArrayIndex[ 1 ] })
      //       this.polygonRef2.current.polygon.setOptions({ fillColor: colorArrayIndex[ 2 ] })
      //       this.polygonRef3.current.polygon.setOptions({ fillColor: colorArrayIndex[ 3 ] })
      //       this.polygonRef4.current.polygon.setOptions({ fillColor: colorArrayIndex[ 4 ] })
      //       this.polygonRef5.current.polygon.setOptions({ fillColor: colorArrayIndex[ 5 ] })
      //       this.polygonRef6.current.polygon.setOptions({ fillColor: colorArrayIndex[ 6 ] })
      //       this.polygonRef7.current.polygon.setOptions({ fillColor: colorArrayIndex[ 7 ] })
      //       this.polygonRef8.current.polygon.setOptions({ fillColor: colorArrayIndex[ 8 ] })
      //       this.polygonRef9.current.polygon.setOptions({ fillColor: colorArrayIndex[ 9 ] })
      //       this.polygonRef10.current.polygon.setOptions({ fillColor: colorArrayIndex[ 10 ] })
      //       this.polygonRef11.current.polygon.setOptions({ fillColor: colorArrayIndex[ 11 ] })
      //       this.polygonRef12.current.polygon.setOptions({ fillColor: colorArrayIndex[ 12 ] })
      //       this.polygonRef13.current.polygon.setOptions({ fillColor: colorArrayIndex[ 13 ] })
      //       this.polygonRef14.current.polygon.setOptions({ fillColor: colorArrayIndex[ 14 ] })
      //       this.polygonRef15.current.polygon.setOptions({ fillColor: colorArrayIndex[ 15 ] })
      //       this.polygonRef16.current.polygon.setOptions({ fillColor: colorArrayIndex[ 16 ] })
      //       this.polygonRef17.current.polygon.setOptions({ fillColor: colorArrayIndex[ 17 ] })
      //       this.polygonRef18.current.polygon.setOptions({ fillColor: colorArrayIndex[ 18 ] })
      //       this.polygonRef19.current.polygon.setOptions({ fillColor: colorArrayIndex[ 19 ] })
      //       this.polygonRef20.current.polygon.setOptions({ fillColor: colorArrayIndex[ 20 ] })
      //       this.polygonRef21.current.polygon.setOptions({ fillColor: colorArrayIndex[ 21 ] })
      //       this.polygonRef22.current.polygon.setOptions({ fillColor: colorArrayIndex[ 22 ] })
      //       this.polygonRef23.current.polygon.setOptions({ fillColor: colorArrayIndex[ 23 ] })
      //       this.polygonRef24.current.polygon.setOptions({ fillColor: colorArrayIndex[ 24 ] })
      //       this.polygonRef25.current.polygon.setOptions({ fillColor: colorArrayIndex[ 25 ] })
      //       this.polygonRef26.current.polygon.setOptions({ fillColor: colorArrayIndex[ 26 ] })
      //       this.polygonRef27.current.polygon.setOptions({ fillColor: colorArrayIndex[ 27 ] })
      //       this.polygonRef28.current.polygon.setOptions({ fillColor: colorArrayIndex[ 28 ] })
      //       this.polygonRef29.current.polygon.setOptions({ fillColor: colorArrayIndex[ 29 ] })
      //       this.polygonRef30.current.polygon.setOptions({ fillColor: colorArrayIndex[ 30 ] })
      //       this.polygonRef31.current.polygon.setOptions({ fillColor: colorArrayIndex[ 31 ] })
      //       this.polygonRef32.current.polygon.setOptions({ fillColor: colorArrayIndex[ 32 ] })
      //       this.polygonRef33.current.polygon.setOptions({ fillColor: colorArrayIndex[ 33 ] })}},1500)
      // }
      )
    }
  }
  
  filterRestClick=()=>{
    this.setState({
      otherLocationLat: null,
      otherLocationLong: null,
      selectedInterval : 'Weekly',
      filterByInterval:'Weekly',
      showingInfoWindow : false,
      filterCCId:null,
      filterCCName:null,
      zoom: false,
      counter: 0,
      zoomIndex:10,
      isDrag:false
    },
    ()=>{  this.componentDidMount();
      
    }
    )
  }

  mapData = (data) => {
    const { browserLanguage,selectedCostCenter } = this.props;
    const jsonBody = getJsonBody();
    this.setState({ loading : true })
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.masterGroupSourceSystemId = getJsonBody().masterGroupSourceSystemId;
    jsonBody.interval=data;
    this.commonService
      .postMethod(apiUrl.weeklyPulseData, jsonBody)
      .then((res) => {
        this.setState({
          intervalSelect: res.data,
          maxLength: res.data.length-1,
          // counter:0,
          loading: false,
          // test: false
        }); 
        const amcol = this.getColors(res.data,-1,true,0);
        // if(amcol)
        // {this.polygonRef.current.polygon.setOptions({ fillColor: amcol[ 0 ] })
        //   this.polygonRef1.current.polygon.setOptions({ fillColor: amcol[ 1 ] })
        //   this.polygonRef2.current.polygon.setOptions({ fillColor: amcol[ 2 ] })
        //   this.polygonRef3.current.polygon.setOptions({ fillColor: amcol[ 3 ] })
        //   this.polygonRef4.current.polygon.setOptions({ fillColor: amcol[ 4 ] })
        //   this.polygonRef5.current.polygon.setOptions({ fillColor: amcol[ 5 ] })
        //   this.polygonRef6.current.polygon.setOptions({ fillColor: amcol[ 6 ] })
        //   this.polygonRef7.current.polygon.setOptions({ fillColor: amcol[ 7 ] })
        //   this.polygonRef8.current.polygon.setOptions({ fillColor: amcol[ 8 ] })
        //   this.polygonRef9.current.polygon.setOptions({ fillColor: amcol[ 9 ] })
        //   this.polygonRef10.current.polygon.setOptions({ fillColor: amcol[ 10 ] })
        //   this.polygonRef11.current.polygon.setOptions({ fillColor: amcol[ 11 ] })
        //   this.polygonRef12.current.polygon.setOptions({ fillColor: amcol[ 12 ] })
        //   this.polygonRef13.current.polygon.setOptions({ fillColor: amcol[ 13 ] })
        //   this.polygonRef14.current.polygon.setOptions({ fillColor: amcol[ 14 ] })
        //   this.polygonRef15.current.polygon.setOptions({ fillColor: amcol[ 15 ] })
        //   this.polygonRef16.current.polygon.setOptions({ fillColor: amcol[ 16 ] })
        //   this.polygonRef17.current.polygon.setOptions({ fillColor: amcol[ 17 ] })
        //   this.polygonRef18.current.polygon.setOptions({ fillColor: amcol[ 18 ] })
        //   this.polygonRef19.current.polygon.setOptions({ fillColor: amcol[ 19 ] })
        //   this.polygonRef20.current.polygon.setOptions({ fillColor: amcol[ 20 ] })
        //   this.polygonRef21.current.polygon.setOptions({ fillColor: amcol[ 21 ] })
        //   this.polygonRef22.current.polygon.setOptions({ fillColor: amcol[ 22 ] })
        //   this.polygonRef23.current.polygon.setOptions({ fillColor: amcol[ 23 ] })
        //   this.polygonRef24.current.polygon.setOptions({ fillColor: amcol[ 24 ] })
        //   this.polygonRef25.current.polygon.setOptions({ fillColor: amcol[ 25 ] })
        //   this.polygonRef26.current.polygon.setOptions({ fillColor: amcol[ 26 ] })
        //   this.polygonRef27.current.polygon.setOptions({ fillColor: amcol[ 27 ] })
        //   this.polygonRef28.current.polygon.setOptions({ fillColor: amcol[ 28 ] })
        //   this.polygonRef29.current.polygon.setOptions({ fillColor: amcol[ 29 ] })
        //   this.polygonRef30.current.polygon.setOptions({ fillColor: amcol[ 30 ] })
        //   this.polygonRef31.current.polygon.setOptions({ fillColor: amcol[ 31 ] })
        //   this.polygonRef32.current.polygon.setOptions({ fillColor: amcol[ 32 ] })
        //   this.polygonRef33.current.polygon.setOptions({ fillColor: amcol[ 33 ] })}

        // console.log(this.state.intervalSelect, 'apires')
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
        Toastr.showErrorMessage('By Terminal API Error');
      });
  };

  mapDataQuarterly = () => {
    const { browserLanguage,selectedCostCenter } = this.props;
    const jsonBody = getJsonBody();
    this.setState({ loading : true })
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.masterGroupSourceSystemId = getJsonBody().masterGroupSourceSystemId;
    jsonBody.interval='Quarterly';
    this.commonService
      .postMethod(apiUrl.weeklyPulseData, jsonBody)
      .then((res) => {
        this.setState({
          QuarterlyData: res.data,
          maxLength: res.data.length-1,
          // counter:0,
          loading: false,
        }); 
        // a = this.getColors(res.data);
        // console.log(this.state.intervalSelect, 'apires')
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
        Toastr.showErrorMessage('By Terminal API Error');
      });
  };

  mapDataMonthly = () => {
    const { browserLanguage,selectedCostCenter } = this.props;
    const jsonBody = getJsonBody();
    this.setState({ loading : true })
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.masterGroupSourceSystemId = getJsonBody().masterGroupSourceSystemId;
    jsonBody.interval='Monthly';
    this.commonService
      .postMethod(apiUrl.weeklyPulseData, jsonBody)
      .then((res) => {
        this.setState({
          MonthlyData: res.data,
          maxLength: res.data.length-1,
          // counter:0,
          loading: false,
        }); 
        // a = this.getColors(res.data);
        // console.log(this.state.intervalSelect, 'apires')
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
        Toastr.showErrorMessage('By Terminal API Error');
      });
  };

  mapDataByArea = (data) => {
    const { browserLanguage,selectedCostCenter } = this.props;
    const jsonBody = getJsonBody();
    this.setState({ loading: true })
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.masterGroupSourceSystemId = getJsonBody().masterGroupSourceSystemId;
    jsonBody.interval=data;
    this.commonService
      .postMethod(apiUrl.weeklyPulseDataByTerminals, jsonBody)
      .then((res) => {
        this.setState({
          intervalSelectByTerminal: res.data,
          counter:0,
          loading: false,
          colorArray:colorArrayIndex
        },
        // ()=>{
        //   if(colorArrayIndex && this.polygonRef && this.polygonRef.current)
        //   {this.polygonRef.current.polygon.setOptions({ fillColor: colorArrayIndex[ 0 ] })
        //     this.polygonRef1.current.polygon.setOptions({ fillColor: colorArrayIndex[ 1 ] })
        //     this.polygonRef2.current.polygon.setOptions({ fillColor: colorArrayIndex[ 2 ] })
        //     this.polygonRef3.current.polygon.setOptions({ fillColor: colorArrayIndex[ 3 ] })
        //     this.polygonRef4.current.polygon.setOptions({ fillColor: colorArrayIndex[ 4 ] })
        //     this.polygonRef5.current.polygon.setOptions({ fillColor: colorArrayIndex[ 5 ] })
        //     this.polygonRef6.current.polygon.setOptions({ fillColor: colorArrayIndex[ 6 ] })
        //     this.polygonRef7.current.polygon.setOptions({ fillColor: colorArrayIndex[ 7 ] })
        //     this.polygonRef8.current.polygon.setOptions({ fillColor: colorArrayIndex[ 8 ] })
        //     this.polygonRef9.current.polygon.setOptions({ fillColor: colorArrayIndex[ 9 ] })
        //     this.polygonRef10.current.polygon.setOptions({ fillColor: colorArrayIndex[ 10 ] })
        //     this.polygonRef11.current.polygon.setOptions({ fillColor: colorArrayIndex[ 11 ] })
        //     this.polygonRef12.current.polygon.setOptions({ fillColor: colorArrayIndex[ 12 ] })
        //     this.polygonRef13.current.polygon.setOptions({ fillColor: colorArrayIndex[ 13 ] })
        //     this.polygonRef14.current.polygon.setOptions({ fillColor: colorArrayIndex[ 14 ] })
        //     this.polygonRef15.current.polygon.setOptions({ fillColor: colorArrayIndex[ 15 ] })
        //     this.polygonRef16.current.polygon.setOptions({ fillColor: colorArrayIndex[ 16 ] })
        //     this.polygonRef17.current.polygon.setOptions({ fillColor: colorArrayIndex[ 17 ] })
        //     this.polygonRef18.current.polygon.setOptions({ fillColor: colorArrayIndex[ 18 ] })
        //     this.polygonRef19.current.polygon.setOptions({ fillColor: colorArrayIndex[ 19 ] })
        //     this.polygonRef20.current.polygon.setOptions({ fillColor: colorArrayIndex[ 20 ] })
        //     this.polygonRef21.current.polygon.setOptions({ fillColor: colorArrayIndex[ 21 ] })
        //     this.polygonRef22.current.polygon.setOptions({ fillColor: colorArrayIndex[ 22 ] })
        //     this.polygonRef23.current.polygon.setOptions({ fillColor: colorArrayIndex[ 23 ] })
        //     this.polygonRef24.current.polygon.setOptions({ fillColor: colorArrayIndex[ 24 ] })
        //     this.polygonRef25.current.polygon.setOptions({ fillColor: colorArrayIndex[ 25 ] })
        //     this.polygonRef26.current.polygon.setOptions({ fillColor: colorArrayIndex[ 26 ] })
        //     this.polygonRef27.current.polygon.setOptions({ fillColor: colorArrayIndex[ 27 ] })
        //     this.polygonRef28.current.polygon.setOptions({ fillColor: colorArrayIndex[ 28 ] })
        //     this.polygonRef29.current.polygon.setOptions({ fillColor: colorArrayIndex[ 29 ] })
        //     this.polygonRef30.current.polygon.setOptions({ fillColor: colorArrayIndex[ 30 ] })
        //     this.polygonRef31.current.polygon.setOptions({ fillColor: colorArrayIndex[ 31 ] })
        //     this.polygonRef32.current.polygon.setOptions({ fillColor: colorArrayIndex[ 32 ] })
        //     this.polygonRef33.current.polygon.setOptions({ fillColor: colorArrayIndex[ 33 ] })}
        // }
        );
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
        Toastr.showErrorMessage('Map By Area API Error');
      });
  };

  onMarkerClick = (props, marker) =>{
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      otherLocationLat:Number(props.position.lat),
      otherLocationLong:Number(props.position.lng),
      isDrag:false
    });
  }
   
  onMapClicked = () => {
    const { showingInfoWindow } = this.state
    if (showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null, 
      });
    }
  };

  percentString = (data) => {
    const index = data.indexOf("%")+1;
    const id = data.substr(0, index);
    return id
  }

  voteString = (data) => {
    const index = data.indexOf("%")+1;
    const text = data.substr(index + 1);
    return text;
  }

  tooltipText = (mood) => (
    <div className = "toolTipStyle">
      <div className = "toolTipTextStyle">
        <img className = "moodImage" alt = "" src = { require('../../images/Excellent.png') } />
        <div style = { { fontFamily: 'Montserrat', fontWeight: '600', fontSize: '16px' } }>{this.percentString(mood[ 0 ].moodDetails)} &nbsp;</div>
        <div style = { { fontFamily: 'Montserrat', fontWeight: '400', fontSize: '16px' } }>{translator.HomePage.votes}</div>
      </div>
      <div className = "toolTipTextStyle">
        <img className = "moodImage" alt = "" src = { require('../../images/Happy.png') } />
        <div style = { { fontFamily: 'Montserrat', fontWeight: '600', fontSize: '16px' } }>{this.percentString(mood[ 1 ].moodDetails)} &nbsp;</div>
        <div style = { { fontFamily: 'Montserrat', fontWeight: '400', fontSize: '16px' } }>{translator.HomePage.votes}</div>
      </div>
      <div className = "toolTipTextStyle">
        <img className = "moodImage" alt = "" src = { require('../../images/Okay2.png') } />
        <div style = { { fontFamily: 'Montserrat', fontWeight: '600', fontSize: '16px' } }>{this.percentString(mood[ 2 ].moodDetails)} &nbsp;</div>
        <div style = { { fontFamily: 'Montserrat', fontWeight: '400', fontSize: '16px' } }>{translator.HomePage.votes}</div>
      </div>
      <div className = "toolTipTextStyle">
        <img className = "moodImage" alt = "" src = { require('../../images/Sad.png') } />
        <div style = { { fontFamily: 'Montserrat', fontWeight: '600', fontSize: '16px' } }>{this.percentString(mood[ 3 ].moodDetails)} &nbsp;</div>
        <div style = { { fontFamily: 'Montserrat', fontWeight: '400', fontSize: '16px' } }>{translator.HomePage.votes}</div>
      </div>
      <div className = "toolTipTextStyle">
        <img className = "moodImage" alt = "" src = { require('../../images/Bad.png') } />
        <div style = { { fontFamily: 'Montserrat', fontWeight: '600', fontSize: '16px' } }>{this.percentString(mood[ 4 ].moodDetails)} &nbsp;</div>
        <div style = { { fontFamily: 'Montserrat', fontWeight: '400', fontSize: '16px' } }>{translator.HomePage.votes}</div>
      </div>
    </div>
  );

  leftClick = () => {
    let { counter, selectedInterval, dataGraph, intervalSelect, colorArray } = this.state
    
    let colarr = this.getColors(intervalSelect, counter , true ,1)
    
    this.setState({
      counter: ++counter,
      // leftclickedfirst: true,
      counter1:0,
      colorArray:colarr
    },()=>{
      // console.log(counter,'left', b,'colour array')
      // if(colorArray && colarr){
      //   this.polygonRef.current.polygon.setOptions({ fillColor: colarr[ 0 ] })
      //   this.polygonRef1.current.polygon.setOptions({ fillColor: colarr[ 1 ] })
      //   this.polygonRef2.current.polygon.setOptions({ fillColor: colarr[ 2 ] })
      //   this.polygonRef3.current.polygon.setOptions({ fillColor: colarr[ 3 ] })
      //   this.polygonRef4.current.polygon.setOptions({ fillColor: colarr[ 4 ] })
      //   this.polygonRef5.current.polygon.setOptions({ fillColor: colarr[ 5 ] })
      //   this.polygonRef6.current.polygon.setOptions({ fillColor: colarr[ 6 ] })
      //   this.polygonRef7.current.polygon.setOptions({ fillColor: colarr[ 7 ] })
      //   this.polygonRef8.current.polygon.setOptions({ fillColor: colarr[ 8 ] })
      //   this.polygonRef9.current.polygon.setOptions({ fillColor: colarr[ 9 ] })
      //   this.polygonRef10.current.polygon.setOptions({ fillColor: colarr[ 10 ] })
      //   this.polygonRef11.current.polygon.setOptions({ fillColor: colarr[ 11 ] })
      //   this.polygonRef12.current.polygon.setOptions({ fillColor: colarr[ 12 ] })
      //   this.polygonRef13.current.polygon.setOptions({ fillColor: colarr[ 13 ] })
      //   this.polygonRef14.current.polygon.setOptions({ fillColor: colarr[ 14 ] })
      //   this.polygonRef15.current.polygon.setOptions({ fillColor: colarr[ 15 ] })
      //   this.polygonRef16.current.polygon.setOptions({ fillColor: colarr[ 16 ] })
      //   this.polygonRef17.current.polygon.setOptions({ fillColor: colarr[ 17 ] })
      //   this.polygonRef18.current.polygon.setOptions({ fillColor: colarr[ 18 ] })
      //   this.polygonRef19.current.polygon.setOptions({ fillColor: colarr[ 19 ] })
      //   this.polygonRef20.current.polygon.setOptions({ fillColor: colarr[ 20 ] })
      //   this.polygonRef21.current.polygon.setOptions({ fillColor: colarr[ 21 ] })
      //   this.polygonRef22.current.polygon.setOptions({ fillColor: colarr[ 22 ] })
      //   this.polygonRef23.current.polygon.setOptions({ fillColor: colarr[ 23 ] })
      //   this.polygonRef24.current.polygon.setOptions({ fillColor: colarr[ 24 ] })
      //   this.polygonRef25.current.polygon.setOptions({ fillColor: colarr[ 25 ] })
      //   this.polygonRef26.current.polygon.setOptions({ fillColor: colarr[ 26 ] })
      //   this.polygonRef27.current.polygon.setOptions({ fillColor: colarr[ 27 ] })
      //   this.polygonRef28.current.polygon.setOptions({ fillColor: colarr[ 28 ] })
      //   this.polygonRef29.current.polygon.setOptions({ fillColor: colarr[ 29 ] })
      //   this.polygonRef30.current.polygon.setOptions({ fillColor: colarr[ 30 ] })
      //   this.polygonRef31.current.polygon.setOptions({ fillColor: colarr[ 31 ] })
      //   this.polygonRef32.current.polygon.setOptions({ fillColor: colarr[ 32 ] })
      //   this.polygonRef33.current.polygon.setOptions({ fillColor: colarr[ 33 ] })}
      if(counter > 7 && selectedInterval === "Weekly")
      {
        this.restrictData(dataGraph)
      }
    }
    );
  };

  rightClick = () => {
    let { counter, counter1, dataGraph, selectedInterval, intervalSelect, colorArray } = this.state
    let colorArr = this.getColors1(intervalSelect, counter - 1, true ,1)
    this.setState({
      counter: --counter,
      counter1: counter1 + 1,
      colorArray:colorArr
    }
    , ()=>{
      // console.log(counter, 'right', c,'colour array')
      // if(colorArray && colorArr)
      // {this.polygonRef.current.polygon.setOptions({ fillColor: colorArr[ 0 ] })
      //   this.polygonRef1.current.polygon.setOptions({ fillColor: colorArr[ 1 ] })
      //   this.polygonRef2.current.polygon.setOptions({ fillColor: colorArr[ 2 ] })
      //   this.polygonRef3.current.polygon.setOptions({ fillColor: colorArr[ 3 ] })
      //   this.polygonRef4.current.polygon.setOptions({ fillColor: colorArr[ 4 ] })
      //   this.polygonRef5.current.polygon.setOptions({ fillColor: colorArr[ 5 ] })
      //   this.polygonRef6.current.polygon.setOptions({ fillColor: colorArr[ 6 ] })
      //   this.polygonRef7.current.polygon.setOptions({ fillColor: colorArr[ 7 ] })
      //   this.polygonRef8.current.polygon.setOptions({ fillColor: colorArr[ 8 ] })
      //   this.polygonRef9.current.polygon.setOptions({ fillColor: colorArr[ 9 ] })
      //   this.polygonRef10.current.polygon.setOptions({ fillColor: colorArr[ 10 ] })
      //   this.polygonRef11.current.polygon.setOptions({ fillColor: colorArr[ 11 ] })
      //   this.polygonRef12.current.polygon.setOptions({ fillColor: colorArr[ 12 ] })
      //   this.polygonRef13.current.polygon.setOptions({ fillColor: colorArr[ 13 ] })
      //   this.polygonRef14.current.polygon.setOptions({ fillColor: colorArr[ 14 ] })
      //   this.polygonRef15.current.polygon.setOptions({ fillColor: colorArr[ 15 ] })
      //   this.polygonRef16.current.polygon.setOptions({ fillColor: colorArr[ 16 ] })
      //   this.polygonRef17.current.polygon.setOptions({ fillColor: colorArr[ 17 ] })
      //   this.polygonRef18.current.polygon.setOptions({ fillColor: colorArr[ 18 ] })
      //   this.polygonRef19.current.polygon.setOptions({ fillColor: colorArr[ 19 ] })
      //   this.polygonRef20.current.polygon.setOptions({ fillColor: colorArr[ 20 ] })
      //   this.polygonRef21.current.polygon.setOptions({ fillColor: colorArr[ 21 ] })
      //   this.polygonRef22.current.polygon.setOptions({ fillColor: colorArr[ 22 ] })
      //   this.polygonRef23.current.polygon.setOptions({ fillColor: colorArr[ 23 ] })
      //   this.polygonRef24.current.polygon.setOptions({ fillColor: colorArr[ 24 ] })
      //   this.polygonRef25.current.polygon.setOptions({ fillColor: colorArr[ 25 ] })
      //   this.polygonRef26.current.polygon.setOptions({ fillColor: colorArr[ 26 ] })
      //   this.polygonRef27.current.polygon.setOptions({ fillColor: colorArr[ 27 ] })
      //   this.polygonRef28.current.polygon.setOptions({ fillColor: colorArr[ 28 ] })
      //   this.polygonRef29.current.polygon.setOptions({ fillColor: colorArr[ 29 ] })
      //   this.polygonRef30.current.polygon.setOptions({ fillColor: colorArr[ 30 ] })
      //   this.polygonRef31.current.polygon.setOptions({ fillColor: colorArr[ 31 ] })
      //   this.polygonRef32.current.polygon.setOptions({ fillColor: colorArr[ 32 ] })
      //   this.polygonRef33.current.polygon.setOptions({ fillColor: colorArr[ 33 ] })}
      if(selectedInterval === "Weekly"){
        this.restrictDataRight(dataGraph)}
    }
    );
  };

  locationFilter = (costCenter,search,interval,latlong,ccWholeData) =>
  {
    const { selectedCostCenter } = this.props
    const { QuarterlyData, MonthlyData } = this.state
    if(costCenter.includes('surrounding')){
      this.setState({
        zoom: true,
      })
    }
    else{(this.setState({
      zoom: false,
    }))}
    if(latlong !== false){
      this.setState(
        {
          otherLocationLong : latlong.lng,
          otherLocationLat : latlong.lat,
        }
      );
    }
    this.setState({
      selectedInterval:interval,
      showingInfoWindow : false,
      filterCCId : ccWholeData !== null ? ccWholeData.costCenterId : selectedCostCenter.costCenterId,
      filterCCName : ccWholeData !== null ? ccWholeData.location : selectedCostCenter.costCenterName,
    },()=>{
      this.mapDataByArea(interval)
      this.dataLineGraph(interval)
      this.mapData(interval)
      setTimeout(()=>{
        const c = this.getColors1(interval === 'Quarterly' ? QuarterlyData : MonthlyData, 0, true ,1)
        // console.log(QuarterlyData,interval ,'asdasdasdasdasdasdasdasdasdas')
        // if(c)
        // {this.polygonRef.current.polygon.setOptions({ fillColor: c[ 0 ] })
        //   this.polygonRef1.current.polygon.setOptions({ fillColor: c[ 1 ] })
        //   this.polygonRef2.current.polygon.setOptions({ fillColor: c[ 2 ] })
        //   this.polygonRef3.current.polygon.setOptions({ fillColor: c[ 3 ] })
        //   this.polygonRef4.current.polygon.setOptions({ fillColor: c[ 4 ] })
        //   this.polygonRef5.current.polygon.setOptions({ fillColor: c[ 5 ] })
        //   this.polygonRef6.current.polygon.setOptions({ fillColor: c[ 6 ] })
        //   this.polygonRef7.current.polygon.setOptions({ fillColor: c[ 7 ] })
        //   this.polygonRef8.current.polygon.setOptions({ fillColor: c[ 8 ] })
        //   this.polygonRef9.current.polygon.setOptions({ fillColor: c[ 9 ] })
        //   this.polygonRef10.current.polygon.setOptions({ fillColor: c[ 10 ] })
        //   this.polygonRef11.current.polygon.setOptions({ fillColor: c[ 11 ] })
        //   this.polygonRef12.current.polygon.setOptions({ fillColor: c[ 12 ] })
        //   this.polygonRef13.current.polygon.setOptions({ fillColor: c[ 13 ] })
        //   this.polygonRef14.current.polygon.setOptions({ fillColor: c[ 14 ] })
        //   this.polygonRef15.current.polygon.setOptions({ fillColor: c[ 15 ] })
        //   this.polygonRef16.current.polygon.setOptions({ fillColor: c[ 16 ] })
        //   this.polygonRef17.current.polygon.setOptions({ fillColor: c[ 17 ] })
        //   this.polygonRef18.current.polygon.setOptions({ fillColor: c[ 18 ] })
        //   this.polygonRef19.current.polygon.setOptions({ fillColor: c[ 19 ] })
        //   this.polygonRef20.current.polygon.setOptions({ fillColor: c[ 20 ] })
        //   this.polygonRef21.current.polygon.setOptions({ fillColor: c[ 21 ] })
        //   this.polygonRef22.current.polygon.setOptions({ fillColor: c[ 22 ] })
        //   this.polygonRef23.current.polygon.setOptions({ fillColor: c[ 23 ] })
        //   this.polygonRef24.current.polygon.setOptions({ fillColor: c[ 24 ] })
        //   this.polygonRef25.current.polygon.setOptions({ fillColor: c[ 25 ] })
        //   this.polygonRef26.current.polygon.setOptions({ fillColor: c[ 26 ] })
        //   this.polygonRef27.current.polygon.setOptions({ fillColor: c[ 27 ] })
        //   this.polygonRef28.current.polygon.setOptions({ fillColor: c[ 28 ] })
        //   this.polygonRef29.current.polygon.setOptions({ fillColor: c[ 29 ] })
        //   this.polygonRef30.current.polygon.setOptions({ fillColor: c[ 30 ] })
        //   this.polygonRef31.current.polygon.setOptions({ fillColor: c[ 31 ] })
        //   this.polygonRef32.current.polygon.setOptions({ fillColor: c[ 32 ] })
        //   this.polygonRef33.current.polygon.setOptions({ fillColor: c[ 33 ] })}
      },1000)
    })
    this.setState({
      isDrag:false
    })
  };

  dataLineGraph = (intervals) => {
    const { filterCCId } = this.state
    const { browserLanguage, selectedCostCenter } = this.props;
    const jsonBody = getJsonBody();
    this.setState({ loading:true })
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId =  filterCCId === null ? selectedCostCenter.costCenterId : filterCCId;
    jsonBody.applicationId = getJsonBody().applicationId;
    jsonBody.masterGroupSourceSystemId = getJsonBody().masterGroupSourceSystemId;
    jsonBody.interval= intervals;
    const data = { ...jsonBody };
    this.commonService
      .postMethod(apiUrl.dailyPulseLineGraph, data)
      .then((res) => {
        this.setState({
          dataGraph: res.data,
          loading: false,
        },()=>this.restrictData(res.data));
      })
      .catch(() => {
        this.setState({ loading: false,isApiError:true });
        // Toastr.showErrorMessage('Api Error'); // not present in figma
      });
  };

  // dataLineGraph1 = (intervals) => {
  //   const { filterCCId } = this.state
  //   const { browserLanguage, selectedCostCenter } = this.props;
  //   const jsonBody = getJsonBody();
  //   this.setState({ loading:true })
  //   jsonBody.entityCode = browserLanguage;
  //   jsonBody.costCenterId =  filterCCId === null ? selectedCostCenter.costCenterId : filterCCId;
  //   jsonBody.applicationId = getJsonBody().applicationId;
  //   jsonBody.masterGroupSourceSystemId = getJsonBody().masterGroupSourceSystemId;
  //   jsonBody.interval= intervals;
  //   const data = { ...jsonBody };
  //   this.commonService
  //     .postMethod(apiUrl.dailyPulseLineGraph, data)
  //     .then((res) => {
  //       this.setState({
  //         dataGraph1: res.data,
  //         // eslint-disable-next-line no-dupe-keys
  //         loading: false,
  //       });
  //     })
  //     .catch(() => {
  //       this.setState({ loading: false });
  //       Toastr.showErrorMessage('Api Error');
  //     });
  // };

  restrictData = (data) =>{
    const { counter, selectedInterval } = this.state
    if(counter < 7 && selectedInterval === "Weekly")
    {
      const arr = []
      let arr1 =[]
      for (let i=data.length-1;i>=data.length-8;i-=1)
      {
        arr.push(data[ i ])
      }
      arr1=arr.reverse()
      this.setState({ fliteredBlock : arr1 })
    }
    if(counter > 7 && selectedInterval === "Weekly")
    {
      const arr = []
      let arr1 =[]
      for (let i=(data.length-1-(counter-7));i>=data.length-1-counter;i-=1)
      {
        arr.push(data[ i ])
      }
      arr1=arr.reverse()
      this.setState({ fliteredBlock : arr1 })
    }
  }

  restrictDataRight = (data) =>{
    const { counter1, counter, selectedInterval } = this.state
    if(counter1 > 7 && selectedInterval === "Weekly") {
      if (counter < data.length-7)
      {
        const arr = []
        let arr1 =[]
        for (let i=(data.length-1-counter);i>=(data.length-1-counter-7);i-=1)
        {
          arr.push(data[ i ])
        }
        arr1=arr.reverse()
        this.setState({ fliteredBlock : arr1 })
      } 
    }
  }

  getColors = 
    (data,cnt,arr,m) =>{
      // console.log("StartLoop",cnt);
      const { counter, intervalSelect } = this.state
      let resColArr
      // if (arr){
      //   cnt = cnt
      // }
      // else{
      //   cnt=counter+1
      // }
      if(cnt>=intervalSelect.length-1){
        cnt=intervalSelect.length-1 -1
      }
      if(cnt<0 && m===1){
        cnt=counter
      }
      // console.log("SSSSSSSSSSSSS",cnt);
      const res = data && data.map((dta) => {
        // console.log(dta.weekNumber,cnt ,intervalSelect[ cnt+1 ].weekNumber,'getColors')
      
        if (dta.weekNumber === intervalSelect[ cnt+1 ].weekNumber){
          resColArr = dta.pulseData.map((dta1)=>dta1.fillColor)
        }
      })
      // console.log(b,"BBBBBB")
      return resColArr
    }

  getColors1 = (data,cnt) =>{
    const { counter, intervalSelect } = this.state
    let resColArr
    // if (arr){
    //   cnt = cnt
    // }
    // else{
    //   cnt=counter+1
    // }
    if(cnt>=intervalSelect.length-1){
      cnt=intervalSelect.length-1 -1
    }
    if(cnt<0){
      cnt=counter
    }
    const res = data && data.map((dta) => {
      // console.log(dta.weekNumber,cnt ,intervalSelect[ cnt ].weekNumber,'getColors')
      
      if (dta.weekNumber === intervalSelect[ cnt ].weekNumber){
        resColArr = dta.pulseData.map((dta1)=>dta1.fillColor)
      }
    })
    // console.log(b,"BBBBBB")
    return resColArr
  }

  getColorsReset = 
    (data,cnt,arr,m) =>{
      // console.log("StartLoop",cnt);
      const { counter, intervalSelect } = this.state
      let resColArr
      // if (arr){
      //   cnt = cnt
      // }
      // else{
      //   cnt=counter+1
      // }
      if(cnt>=intervalSelect.length-1){
        cnt=intervalSelect.length-1 -1
      }
      if(cnt<0 && m===1){
        cnt=counter
      }
      // console.log("SSSSSSSSSSSSS",cnt);
      const res = data && data.map((dta) => {
        // console.log(dta.weekNumber,cnt ,intervalSelect[ cnt+1 ].weekNumber,'getColors')
      
        if (dta.weekNumber === intervalSelect[ cnt ].weekNumber){
          resColArr = dta.pulseData.map((dta1)=>dta1.fillColor)
        }
      })
      // console.log(b,"BBBBBB")
      return resColArr
    }

  // centerFunction = () =>{
  //   const { google } = this.props;
  //   const { regionMock } = this.state;
  //   const bounds = new google.maps.LatLngBounds();
  //   let i;

  //   // The Bermuda Triangle
  //   const polygonCoords =  regionMock && regionMock.regions[ 28 ].coordinates;

  //   for (i = 0; i < polygonCoords.length; i+=1) {
  //     bounds.extend(polygonCoords[ i ]);
  //     // console.log(bounds.extend(polygonCoords[ i ]),'HHIHIHHIHIHihi')
  //   }

  //   // The Center of the Bermuda Triangle - (25.3939245, -72.473816)
  //   // console.log(bounds.getCenter().toString(),"CENTER"); 
  //   // return( bounds.getCenter().toString() )
  // }

  render() {
    const { browserLanguage, selectedCostCenter, google } = this.props;
    const { isApiError,zoomIndex,isDrag,selectedInterval, colorArray, counter, intervalSelect, otherLocationLong,
      otherLocationLat, loading, zoom, regionMock, activeMarker, showingInfoWindow,
      selectedPlace, intervalSelectByTerminal, fliteredBlock, dataGraph, maxLength, filterCCName,counter1 } = this.state;
    translator.setLanguage(browserLanguage);
    // this.centerFunction();
    return (
      <div className = "dailyPulseContainer">
        { isApiError === true ? <CustomApiErrorDialog /> : null }
        <Dialog visible = { loading } id = "loader">
          <Loading preset = "brandPink" size = "xl" />
        </Dialog>
        <div className = "dailyPulseLeftSection">
          <div className = "dailyPulseScreenHeaderContainer">
            <div className = "dailyPulseScreenHeader">{translator.sideMenu.dailyPulse}</div>
            <div className = "weekHeading" >
              <button type = "button" className = "dailyPulseDateButton" onClick = { this.leftClick } disabled = { counter === maxLength }>
                <img src = { require('../../images/leftBlue.png') } alt = "" />
              </button>
              {intervalSelect && (browserLanguage === "en" ? intervalSelect && intervalSelect[ counter ].weekNumber: intervalSelect[ counter ].weekNumberFi ) }
              <button type = "button" className = "dailyPulseDateButton" onClick = { this.rightClick } disabled = { counter === 0 }>
                <img src = { require('../../images/rightBlue.png') } alt = "" />
              </button>
            </div>
          </div>
          <div className = "mainMapsContainer">
            <div className = "mapContainer">
              <div className = "mapHeading">{translator.DailyPulse.byTerminals}</div>
              <Map google = { google }
                style = { { width: '100%', height: '100%', position: 'relative', borderRadius: '16px' } }
                className = "map"
                zoom = { zoom === true ? 8 : 10 }
                initialCenter = { isDrag?{}:{ lng: 26.603953, lat: 62.947391 } }
                center = { isDrag?{}:{ lng: otherLocationLong === null ? 
                  selectedCostCenter.longitude : otherLocationLong, 
                lat: otherLocationLat === null ? 
                  selectedCostCenter.latitude : otherLocationLat } }
                onClick = { this.onMapClicked }
                styles = { mapStyles }      
                onZoomChanged = { (e,zoomLevel) => {
                  this.setState({
                    zoomIndex:zoomLevel.zoom
                  },() =>{
                    this.setState({
                      isDrag:true
                    })
                  }) 
                }
                }
                streetViewControl  = { false }
                onDragend = { () => this.setState({
                  isDrag:true
                })
                }
              >
                { intervalSelect && ( intervalSelect[ counter ].pulseData.map((data) => (
                  <Marker
                    onClick = { this.onMarkerClick }
                    name = { this.tooltipText(data.mood) }
                    position = { data.markerDetails }
                    // position = { this.centerFunction() }
                    icon = { {
                      url: data.moodIndicator,
                      anchor: new google.maps.Point(20, 20),
                      scaledSize: new google.maps.Size(45, 40),
                    } }
                    label = { { text:`${ data.regionName }`,fontSize:zoomIndex >= 8 ? "12px" : "0px", fontFamily:"Montserrat", fontWeight:"600", color:"#3B4A57" } }
                    title = "Click for more info"
                  />

                )))}
                
                {/* <Polygon
                  ref = { this.polygonRef }
                  paths = { regionMock && regionMock.regions[ 0 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 0 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef1 }
                  paths = { regionMock && regionMock.regions[ 1 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 1 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef2 }
                  paths = { regionMock && regionMock.regions[ 2 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 2 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef3 }
                  paths = { regionMock && regionMock.regions[ 3 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 3 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef4 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 4 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 4 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef5 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 5 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 5 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef6 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 6 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 6 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef7 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 7 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 7 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef8 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 8 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 8 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef9 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 9 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 9 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef10 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 10 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 10 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef11 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 11 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 11 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef12 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 12 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 12 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef13 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 13 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 13 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef14 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 14 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 14 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef15 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 15 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 15 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef16 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 16 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 16 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef17 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 17 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 17 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef18 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 18 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 18 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef19 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 19 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 19 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef20 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 20 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 20 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef21 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 21 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 21 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef22 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 22 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 22 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef23 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 23 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 23 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef24 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 24 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 24 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef25 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 25 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 25 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef26 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 26 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 26 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef27 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 27 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 27 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef28 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 28 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 28 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef29 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 29 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 29 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef30 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 30 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 30 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef31 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 31 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 31 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef32 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 32 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 32 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                />
                <Polygon
                  ref = { this.polygonRef33 }
                  paths = { regionMock && regionMock.regions && regionMock.regions[ 33 ].coordinates }
                  strokeColor = "white"
                  strokeOpacity = { 0.8 }
                  strokeWeight = { 2 }
                  fillColor =  { colorArray && colorArray.length !== 0 && colorArray[ 33 ] }
                  fillOpacity = { 0.6 }
                  onClick = { this.onMapClicked }
                /> */}
                <InfoWindow
                  marker = { activeMarker }
                  visible = { showingInfoWindow }
                  style = { { width: '50px', height: '1000px' } }
                >
                  <div>
                    <h5>{selectedPlace.name}</h5>
                  </div>
                </InfoWindow>
              </Map>
            </div>
            {intervalSelectByTerminal && 
            <div className = "SvgMap">
              <div className = "mapHeading">{translator.DailyPulse.byAreas}</div>
              <svg display = "flex" width = "172" height = "320" viewBox = "0 0 172 320" fill = "#FADEA8" xmlns = "http://www.w3.org/2000/svg">
                <path fillRule = "evenodd" clipRule = "evenodd"
                  d = "M3.08557 318.79L2.02395 317.022L3.0148 313.414L4.00565 311.786L3.8641 310.371L5.35037 308.744L5.20882 308.108L3.2979 307.4V305.632L4.57184 303.934V303.438L2.02395 
                         303.297L1.10387 301.882L1.8824 298.911L1.3162 298.274L1.67008 295.727L2.87325 293.746L2.80247 293.11L2.51937 292.897L1.5993 293.676L0.75 292.685L1.24542 289.784L1.95317 
                         289.006L2.66092 288.37L2.7317 287.521L1.8824 286.53L2.1655 285.398L3.65177 285.469L4.21797 284.903L4.57184 282.568L6.62432 281.861L6.69509 282.639L7.61517 282.568L7.96904 
                         282.215L7.82749 280.871L9.24299 279.526L9.31376 278.253L8.88911 277.262L7.33207 276.131L8.25214 274.645L9.66763 275.565L10.3754 275.282L11.5078 275.565L11.7201 275.494L11.437 
                         274.928L10.1631 273.796L9.95074 272.523L11.0831 271.178L11.1539 268.419L12.6402 266.792L13.3479 268.985H14.4803L17.5944 267.57L22.0532 269.127L23.398 268.844L24.4596 
                         266.934H25.8043L26.229 265.943L24.7427 262.689L24.0349 261.981L23.6811 260.779L24.3888 259.859L25.3089 259.788L25.8751 257.171L27.149 256.463L27.7152 257.171L28.0691 
                         255.685L29.2015 254.836L29.3431 254.199L30.6878 252.997L31.3955 252.714L31.6078 251.653L33.1649 250.733L33.3772 249.459L32.9526 247.196L34.3681 246.276L35.8543 244.012L35.9959 
                         244.578H37.6237L38.4022 245.498H38.8977L39.6054 244.436L45.4797 244.154L45.6213 238.494L46.966 237.503L48.523 238.989L49.3016 238.848L49.4431 236.088L50.5047 234.461L50.2924 
                         234.32L51.4248 232.551L52.3449 231.773L53.6188 229.863L54.6805 230.57L55.1051 230.358V228.589L56.096 226.892L56.4498 225.972L59.0685 223.142L61.1917 221.727L61.3333 220.878L62.9611 
                         219.675L64.2351 214.228L66.6414 212.53L67.2784 209.064L68.9062 208.851L69.4724 207.932L70.534 207.083H73.2235L74.4267 206.163H76.9746L79.664 207.649L79.8763 208.922H80.5841L80.9379 
                         208.639L80.5133 207.436L78.0362 204.96L77.8238 203.687L78.2485 202.484L80.3717 202.697L82.2119 204.182H82.495V203.97L81.3626 202.838L80.9379 200.15L80.3717 199.088L78.8147 198.169L78.1069 
                         196.683L79.3101 195.127V191.377L78.5316 189.962L79.3809 188.901V187.062L80.4425 185.93L80.5133 184.656L78.4608 180.978L78.1777 181.331H76.833L76.7622 179.492L76.4083 179.138L76.5499 
                         178.997L76.0545 178.572L74.8513 178.643L73.7897 177.228L72.3034 177.016L71.5249 177.653L70.0386 177.865L69.614 176.379L68.4816 177.157L67.4907 175.955L68.4108 173.974L67.703 
                         173.054L65.1552 172.064L64.1643 170.861L63.3858 172.134L61.4041 172.347V169.234L60.1301 165.838L59.9178 163.362L57.7946 159.895L57.653 157.349L55.1051 154.872L54.0435 149.708L54.6097 
                         148.222L54.1143 146.1L54.9636 143.695L55.5298 141.36L57.7946 139.45L58.2192 132.446L60.3425 130.041L54.3974 116.316L54.5389 112.001L57.4407 110.515L56.7329 108.322L57.5115 106.624L54.3266 
                         106.129L52.6988 103.228L54.6805 98.2759L54.5389 91.2013L54.6097 88.7252L55.7421 86.8151L57.016 85.4002L53.8312 82.0751L53.1234 78.6793L51.2833 78.9623L50.434 76.4862V72.9489L44.6304 
                         67.643L39.2515 65.1669L35.005 64.2472L33.8726 60.8514L31.0417 59.5072L29.9093 56.8189L26.9367 55.8992L22.8318 47.339L19.8592 46.49L19.6469 43.0235L15.7543 38.0005L19.0807 34.7462L23.0441 
                         37.7883L23.4687 36.1611L22.1948 32.6946L24.5304 29.8648L27.9983 28.0961L33.5188 30.1478L42.5072 48.3294L42.2241 52.1497L44.135 51.3715L48.0984 54.2721L52.2741 54.6965L53.0526 56.3944L56.4498 
                         54.9088L59.7763 53.7061L61.1917 51.3007L64.0935 49.7443L69.4724 53.7061L75.0636 56.1114L77.7531 59.5072L78.3901 58.2338H80.4425L80.0886 53.9183L83.4151 49.1076L85.963 47.5512L88.2985 48.1879L89.5017 
                         44.0847L89.0063 36.2319L91.3419 22.9317L92.4743 20.8093L91.7665 18.7577L93.7482 15.6449L97.3577 12.4613L99.6225 7.15541L103.444 5.81124L109.602 7.4384L114.839 3.2644L116.325 1.14203L118.378 
                         1.77874L120.501 0.43457L124.111 5.52826L127.579 7.72138L127.862 10.056L138.903 15.5034L142.724 24.9833L136.072 33.6143L135.718 37.6468L136.992 42.3868L130.763 47.6927H134.161L135.435 
                         49.7443L134.515 55.7577L130.976 62.2663L134.019 74.859L143.078 78.6086L149.094 90.7768L154.968 95.2338L154.402 101.176L142.017 124.452L141.167 127.423L149.236 141.006L150.793 145.251L150.651 
                         145.463L152.916 151.618L156.03 156.217L159.781 166.899L160.984 175.035L158.295 175.247L155.11 177.44L156.384 179.563L155.747 181.685H155.818L155.322 183.524L155.039 187.628L153.695 191.943L156.738 
                         192.226L158.083 193.712L156.95 195.905L157.799 197.108L156.667 199.442L154.331 200.22L153.695 202.484L155.464 209.347L161.975 210.691L161.338 212.884L162.683 214.582L162.612 217.412L161.197 
                         218.614V221.303L163.108 226.326L169.973 231.066L171.388 237.503L167 248.964L163.108 251.653L163.745 252.289L159.781 251.936L157.799 254.058L155.039 247.054L142.654 249.318L134.373 247.762L130.834 
                         255.827L125.385 249.53L117.741 244.578L111.371 244.154L105.851 242.031H105.78L101.604 243.446L95.5176 249.106L96.4377 258.656L95.3052 259.364L93.182 265.306H88.794L87.6616 262.76L86.5999 
                         261.911H83.6274L77.8238 257.878L76.3376 258.515L75.4883 263.679L73.365 263.962H73.2943L71.9495 264.387L69.1185 267.5L68.6939 276.555L65.509 275.848L62.3242 276.98L61.4748 277.545L61.2625 
                         277.475L60.4132 278.677L60.9087 283.276L62.7488 285.681L61.6164 286.672L64.3766 294.312L63.7397 295.303L64.5889 296.435L63.8104 301.741L60.9087 301.882L60.0593 303.297L56.7329 302.802L54.1143 
                         305.844L52.2741 305.066L51.9202 302.023L50.7878 301.316L47.603 303.934L45.1259 303.58L44.5597 304.924L41.0209 303.58L39.2515 306.551L35.1466 308.886H29.9093L27.8568 307.825L26.229 311.15L24.1765 
                         311.22L21.5578 315.324H20.0008L15.1173 319.427L10.5877 318.578L8.95989 317.092L5.56269 317.446L3.2979 319.568L3.08557 318.79Z"
                  fill = { intervalSelectByTerminal && intervalSelectByTerminal[ counter ] ? intervalSelectByTerminal[ counter ].pulseData[ 2 ].fillColor : this.setState({ counter:0 }) }
                />
                <image
                  width = "40px"
                  height = "40px"
                  href = { intervalSelectByTerminal && intervalSelectByTerminal[ counter ] ? intervalSelectByTerminal[ counter ].pulseData[ 2 ].moodIndicator : this.setState({ counter:0 }) }
                  transform = "translate(90, 160)"
                />
              </svg>
              <svg className = "reg2" width = "140" height = "144" viewBox = "0 0 140 144" fill = "none" xmlns = "http://www.w3.org/2000/svg">
                <path fillRule = "evenodd" clipRule = "evenodd"
                  d = "M137.431 37.7482L132.972 34.1402L131.486 29.1172L128.301 26.9241L124.267 23.7406L118.605 21.1937L116.34 18.0102L109.687 10.8649L106.502 10.5819L104.804 12.4213L102.185 5.70042L89.5164 
              7.96429L81.0234 6.40787L79.1125 10.9356L77.7678 14.1192L72.672 8.24727L64.8867 3.22432L58.4462 2.72911L52.9966 0.67749L48.6793 2.16313L42.3803 7.96429L43.3004 17.515L42.2388 18.1517L40.1863 
              23.9528L39.9032 23.8821H36.0106L34.8782 21.4767L33.675 20.4863H30.7025L24.8282 16.4538L22.988 17.232L22.1387 22.3257L19.8739 22.6087L18.3169 22.8916L15.2735 26.1459L14.9197 34.9184L12.0179 
              34.2817L8.69148 35.4844L7.91297 35.9796L6.63902 37.7482L7.20522 42.5589L8.90381 44.8228L7.77141 45.7425L10.6024 53.4538L9.89466 54.4442L10.8147 55.7176L10.107 60.5991L7.27599 60.6698L6.49747 
              62.0847L3.17104 61.5895L0.90625 64.6316V68.1689L3.5957 69.796L5.01119 69.513L6.00204 72.2721L8.62072 72.7673L10.2485 72.2014L13.0795 75.1727L13.9996 78.4977L12.1595 81.3983L11.5933 80.4079L9.89466 
              82.884L14.2119 88.2606L15.132 88.4021V89.1803L14.2119 89.2511L13.9288 88.4729L13.2211 88.9681L13.575 90.1708L13.4334 91.6564L14.3535 92.2224L14.6366 92.0809L15.9105 92.8591L14.7073 93.7081L14.1411 
              94.9107L14.4243 96.5379L16.7598 98.448L16.6183 101.419L16.5475 101.49L15.4859 100.853L15.769 107.503L18.8831 108.777L18.4584 110.263L19.9447 109.555L21.7848 110.97L22.0679 115.71L18.6 117.762L17.6799 
              121.582L19.8739 124.27L18.6 125.331L19.8739 126.959L21.0063 126.676L23.625 129.788L25.6067 127.666L26.1729 128.232L27.0929 131.416L28.1546 132.406L31.6933 131.274L32.7549 131.486L34.4535 130.284L34.5243 
              132.052L37.0722 131.486L38.7 132.901L40.3986 131.981L40.611 130.213L40.8233 130.284L42.8757 131.274L43.8666 133.821L45.0698 133.892L45.7067 135.519L47.4053 135.66L48.1839 134.104L49.5286 134.175L50.3071 
              136.226L48.6085 136.297L45.9898 139.056L45.8483 141.957L47.83 142.098L48.6793 141.532L49.4578 142.381L50.7318 142.31L51.7226 141.391L53.4212 143.089L54.9075 143.159L55.4029 142.24L55.3321 139.905L56.8184 
              138.419L60.4279 139.622L63.4005 139.976L64.5329 141.674L65.4529 140.825L66.1607 140.329L66.9392 140.754L68.1424 140.542H69.1332L69.7702 139.41L70.2656 137.287L70.9026 136.792L72.4596 137.783L73.7336 
              136.934L73.8044 135.66L75.7861 133.75L75.503 133.609L80.528 129.152L81.448 126.676L86.2607 122.431L88.8086 121.865L89.0917 119.177L91.4981 115.073L93.4798 114.295L99.071 110.404L103.813 102.339L104.521 
              100.075L107.706 97.8113L110.253 92.4346L114.075 88.9681L115.562 84.7234L115.915 84.3696L115.986 84.2281L118.251 81.8935L123.276 73.0503L126.886 67.4614L129.292 66.5417L133.751 58.2645L138.988 41.6392L137.431 37.7482Z"
                  fill = { intervalSelectByTerminal && intervalSelectByTerminal[ counter ] ? intervalSelectByTerminal[ counter ].pulseData[ 0 ].fillColor : this.setState({ counter:0 }) }
                  stroke = "white"
                />
                <image
                  width = "40px"
                  height = "40px"
                  href = { intervalSelectByTerminal && intervalSelectByTerminal[ counter ] ? intervalSelectByTerminal[ counter ].pulseData[ 0 ].moodIndicator : this.setState({ counter:0 }) }
                  transform = "translate(35, 55)"
                />
              </svg>
              <svg className = "reg3" width = "108" height = "107" viewBox = "0 0 108 107" fill = "none" xmlns = "http://www.w3.org/2000/svg">
                <path d = "M30.1602 104.958L31.2926 103.756L34.7605 102.482L35.3267 101.775L35.6806 99.5817L35.3267 99.0865L35.0436 99.2987L34.9729 101.28L34.1943 102.058L31.5757 101.633L30.1602 99.7939L30.514 
              97.8838L32.2834 97.8131L33.1327 98.2375L33.5574 94.4172L33.4866 94.3465L33.5574 93.8513L32.6373 93.2853L31.6464 92.7194L31.0802 89.6773L33.7697 86.3523L34.6898 85.2911L34.3359 84.6543L30.1602 
              87.9087L26.9753 88.8284L25.6306 87.6257L23.4366 87.6964V85.9985L25.1352 83.2394L24.4982 82.7442L23.932 83.2394L18.907 84.2299L17.5622 82.9565L16.3591 82.8857L16.5714 80.1266L16.5006 80.0559L15.3682 
              80.7633L13.5989 79.0655L14.3774 78.1457L15.1559 78.2165V77.7213L14.3774 76.9431L11.9003 77.0138L9.35236 77.6505L9.56469 75.8819L10.6263 74.3962L11.5464 74.467L12.1834 72.7691L11.8295 72.6276L9.56469 
              73.8303L8.92771 76.5894L6.94602 76.8016L6.30904 74.2547L4.46889 72.6983L2.98262 68.9488L3.0534 66.7557L2.41642 65.7652L2.34565 64.0674L2.06255 62.8647L2.34565 61.945L2.7703 61.662L2.84107 
              61.379L1.0717 60.3178L0.859375 59.1152L2.62875 58.7614L2.69952 58.1955L1.70867 57.205L2.55797 56.3561L3.69037 56.4268L3.12417 53.4555L4.68122 52.6065L5.10587 53.2433L5.74284 51.9698L6.73369 
              46.1687L7.86609 46.9469L8.07841 46.6639L8.00764 45.8857L8.57384 42.8436L7.29989 39.7308L7.65376 39.0941L8.43229 38.8111L9.35236 39.1649L9.56469 39.0941L9.35236 38.4574L7.86609 37.6792L7.65376 
              36.0521L9.14003 36.1228L9.98933 36.335L9.35236 35.8398L8.29074 33.7882L8.85694 32.727L9.91856 32.8685L11.051 34.1419H11.1925L9.77701 30.8876V27.6333L8.00764 25.3694L8.50306 24.2375L9.42314 
              24.1668V24.096L8.71539 23.6008L8.21996 20.6295L7.08757 19.922L6.73369 18.5779L8.85694 16.597L13.0327 16.1725L14.802 17.7996L18.7654 18.5071L23.6489 14.4039H25.0644L27.683 10.3713L29.6647 10.3006L31.4341 
              6.69258L33.982 7.966H35.6806V8.39048H38.8655L42.6873 6.26811L44.5982 2.94305L48.0662 4.28723L48.5616 3.08455L51.3219 3.43828L54.6483 0.679199L56.4884 1.81113L56.8423 4.78244L58.2578 5.34842V9.02718L60.6641 
              10.5128L62.2212 10.2299L63.212 13.0597L65.5476 13.4842L67.317 12.8474L70.3603 16.031L71.3511 19.5683L69.0863 23.1056L68.5201 22.1151L67.317 23.8838L71.3511 28.9775L72.4128 29.119V30.5339L70.9265 30.6754L70.7142 
              30.1094L70.5726 30.1801L70.8557 31.1706L70.7142 32.5855L71.2804 32.9392L71.5635 32.7977L73.4744 33.9297L71.9173 35.0616L71.4219 36.0521L71.6342 37.467L74.0406 39.3771L73.8283 43.1266L72.8374 42.4899L73.0497 
              48.1496L76.3762 49.423L76.0931 50.4842L77.0839 49.9889L79.2072 51.6161L79.4902 56.7098L75.9515 58.7614L75.1022 62.2987L77.4378 65.1285L76.1638 66.1897L77.0839 67.3216L78.2871 67.0387L80.7642 70.01L82.6751 
              68.0291L83.666 68.8073L84.5153 71.9909L85.3646 72.8398L88.8325 71.7786L89.8234 71.9909L91.9466 70.5052L92.0174 72.4153L94.353 71.9909L95.8393 73.335L97.184 72.6276L97.4671 70.576L100.015 71.8494L100.935 
              74.3255H102.138L102.846 76.0234L104.12 76.0941L104.898 74.5377L106.739 74.7499L107.8 77.509H105.748L103.341 80.0559L103.2 83.2394H102.846L100.864 85.2203H99.5195L98.8826 83.7347L98.2456 83.8054L98.3871 
              85.5033L97.6086 86.8475L96.4054 86.9889L95.6977 85.3618L94.353 84.0177L92.9375 82.6027L92.8667 82.815L93.9991 84.3714L94.353 85.7155L95.6977 88.0501L94.4945 88.6869L93.5037 88.1916L93.2914 88.2624L93.4329 
              89.8895L92.2297 90.314L91.3096 88.4039L91.8051 90.031L90.9558 91.163L89.9649 90.5262L89.0449 91.446L88.1248 91.2337L86.4262 89.1113L86.0015 87.4842L84.586 87.6257V89.1113L83.5952 90.314L80.3395 90.3848L78.7117 
              90.8092L78.2163 90.9507L78.004 91.7289L76.8008 93.2146L75.7392 92.9316L75.8807 94.1343L73.899 95.7614L73.6867 94.5587L73.4036 94.9832H71.2096L70.0064 94.1343L66.5384 95.337L66.1138 94.9125L66.043 94.9832V96.3274L62.1504 
              101.28L60.0979 101.067L61.8673 98.8035L61.655 98.662L60.8765 99.2987H59.7441L59.8856 97.1763L57.55 98.4498L53.7282 98.5205L49.1278 100.501L47.2169 100.714L47.4292 101.704L46.3676 102.624L44.669 102.27L42.6165 102.694L41.5549 
              101.916L40.281 101.775L39.2194 102.977V103.897L37.3792 105.454H34.1943L31.4341 106.586L30.1602 104.958ZM5.03509 58.5492L5.10587 58.2662L4.18579 57.3465L4.61045 58.1955L5.03509 58.5492Z"
                fill = { intervalSelectByTerminal && intervalSelectByTerminal[ counter ] ? intervalSelectByTerminal[ counter ].pulseData[ 1 ].fillColor : this.setState({ counter:0 }) } 
                />
                <image
                  width = "40px"
                  height = "40px"
                  href = { intervalSelectByTerminal && intervalSelectByTerminal[ counter ] ? intervalSelectByTerminal[ counter ].pulseData[ 1 ].moodIndicator : this.setState({ counter:0 }) }
                  transform = "translate(25, 35)"
                />
              </svg>
            </div>
            }
          </div>
          <DailyPulseGraph respData = { selectedInterval ==="Weekly" ? fliteredBlock : selectedInterval ==="Monthly" ? dataGraph:dataGraph } 
            respGrid = { selectedInterval ==="Weekly" ? weekGrid : selectedInterval ==="Monthly" ? monthGrid:quaterGrid } 
            selectedInterval = { selectedInterval } counter = { counter } counter1 = { counter1 } filterCCName = { filterCCName } dataLength = { dataGraph.length }
          />
          <div className = "dailyPulseFooterContainer">
            <div style = { { border: '1px solid #C2C9D1' } } />
            <div
              style = { {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                height: '8vh',
              } }
            >
              <div className = "resetButtonText" 
                aria-hidden = "true"
                onClick = { () => {
                  this.dataLineGraph(selectedInterval);
                  const m = this.getColorsReset(intervalSelect,0,true,1);
                  // console.log(m,"sssssssssssaaaaaaaaaaaaaa");
                  this.setState({ counter: 0, showingInfoWindow: false, colorArray: colorArrayIndex,isDrag:false },()=>{
                    // if(m)
                    // {this.polygonRef.current.polygon.setOptions({ fillColor: m[ 0 ] })
                    //   this.polygonRef1.current.polygon.setOptions({ fillColor: m[ 1 ] })
                    //   this.polygonRef2.current.polygon.setOptions({ fillColor: m[ 2 ] })
                    //   this.polygonRef3.current.polygon.setOptions({ fillColor: m[ 3 ] })
                    //   this.polygonRef4.current.polygon.setOptions({ fillColor: m[ 4 ] })
                    //   this.polygonRef5.current.polygon.setOptions({ fillColor: m[ 5 ] })
                    //   this.polygonRef6.current.polygon.setOptions({ fillColor: m[ 6 ] })
                    //   this.polygonRef7.current.polygon.setOptions({ fillColor: m[ 7 ] })
                    //   this.polygonRef8.current.polygon.setOptions({ fillColor: m[ 8 ] })
                    //   this.polygonRef9.current.polygon.setOptions({ fillColor: m[ 9 ] })
                    //   this.polygonRef10.current.polygon.setOptions({ fillColor: m[ 10 ] })
                    //   this.polygonRef11.current.polygon.setOptions({ fillColor: m[ 11 ] })
                    //   this.polygonRef12.current.polygon.setOptions({ fillColor: m[ 12 ] })
                    //   this.polygonRef13.current.polygon.setOptions({ fillColor: m[ 13 ] })
                    //   this.polygonRef14.current.polygon.setOptions({ fillColor: m[ 14 ] })
                    //   this.polygonRef15.current.polygon.setOptions({ fillColor: m[ 15 ] })
                    //   this.polygonRef16.current.polygon.setOptions({ fillColor: m[ 16 ] })
                    //   this.polygonRef17.current.polygon.setOptions({ fillColor: m[ 17 ] })
                    //   this.polygonRef18.current.polygon.setOptions({ fillColor: m[ 18 ] })
                    //   this.polygonRef19.current.polygon.setOptions({ fillColor: m[ 19 ] })
                    //   this.polygonRef20.current.polygon.setOptions({ fillColor: m[ 20 ] })
                    //   this.polygonRef21.current.polygon.setOptions({ fillColor: m[ 21 ] })
                    //   this.polygonRef22.current.polygon.setOptions({ fillColor: m[ 22 ] })
                    //   this.polygonRef23.current.polygon.setOptions({ fillColor: m[ 23 ] })
                    //   this.polygonRef24.current.polygon.setOptions({ fillColor: m[ 24 ] })
                    //   this.polygonRef25.current.polygon.setOptions({ fillColor: m[ 25 ] })
                    //   this.polygonRef26.current.polygon.setOptions({ fillColor: m[ 26 ] })
                    //   this.polygonRef27.current.polygon.setOptions({ fillColor: m[ 27 ] })
                    //   this.polygonRef28.current.polygon.setOptions({ fillColor: m[ 28 ] })
                    //   this.polygonRef29.current.polygon.setOptions({ fillColor: m[ 29 ] })
                    //   this.polygonRef30.current.polygon.setOptions({ fillColor: m[ 30 ] })
                    //   this.polygonRef31.current.polygon.setOptions({ fillColor: m[ 31 ] })
                    //   this.polygonRef32.current.polygon.setOptions({ fillColor: m[ 32 ] })
                    //   this.polygonRef33.current.polygon.setOptions({ fillColor: m[ 33 ] })}
                  })} }
              >
                {translator.DailyPulse.resetText}
              </div>
            </div>
          </div>
        </div>
        <div className = "dailyPulseRightSection">
          <div style = { { paddingBottom: '8vh' } }>
            <div className = "dailyPulseRightHeading">{translator.DailyPulse.filter}</div>
            <DailyPulseFilter 
              onlocationChange = { this.locationFilter }
              onRestClick = { this.filterRestClick }
            />
          </div>
        </div>
      </div>
    );
  }
}
  
const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});
const mapDispatchToProps = () => ({});
export const Check = connect(mapStateToProps, mapDispatchToProps)(DailyPulse);
export default GoogleApiWrapper({
  //apiKey: 'AIzaSyDqj7t7cARh1s4B9SaDXc58-bIx50eIS3M',
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
})(Check);